import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { store } from "../store/store";

export const base_url = window.location.href.includes("worldring")
  ? "https://marketplace.worldring.org/api"
  : "https://wrl-v1.untld.io/api";

export const maintenance_pw = "W03ldRing#20";
export const fadeProperties = {
  duration: 1000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: false,
};

export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const InfoTooltip = ({ text }) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip className="mytooltip" id="button-tooltip">
          {text}
        </Tooltip>
      }
    >
      <FontAwesomeIcon className="ml-2" icon={faInfoCircle} />
    </OverlayTrigger>
  );
};

export const routesNames = {
  "/company-profile/user": "Home > My Profile",
  "/newsfeed": "Newsfeed",
  "/jumpseat/newsfeed": "Newsfeed",
  "/blog/newsfeed": "Blogs",
  "/admin-page": "Admin Page",
  "/agent/search": "Agent Search",
  search: "Search",
  "/my-clients": "My Clients",
  "/my-matches": "My Matches",
  "/my-products": "My Products",
  "/sales-page": "Sales Page",
  "/sales-support": "Sales Support",
  "/commodities-search": "Commodities Search",
  "/product/detail": store.getState().breadcrumbs.activeProduct,
  "/jumpseat/invite": "Jumpseat Invite",
  "/my-customers": "My Customers",
  "/client-matches": "Client Matches",
  "/jumpseat/my-matches": "Jumpseat Matches",
  "/company-profile/": store.getState().breadcrumbs.activeCompany,
  "/jumpseat/hs-codes": "Jumpseat HS Codes",
  "/membership/payment": "Payment",
  "/impressum": "Impressum",
  "/membership-agreement": "Membership Agreement",
  "/privacy-policy": "Privacy Policy",
};

export const menuItems = {
  "/agent/search": "Agent Search",
  "/my-clients": "My Clients",
  "/commodities-search": "Commodities Search",
  "/my-products": "My Products",
  "/admin-page": "Admin Page",
  "/my-matches": "My Matches",
  "/sales-page": "Sales Page",
  "/sales-support": "Sales Support",
  "/my-customers": "My Customers",
};

export const incotermOptions = [
  { value: "EXW", label: "EXW" },
  { value: "FCA", label: "FCA" },
  { value: "FAS", label: "FAS" },
  { value: "FOB", label: "FOB" },
  { value: "CFR", label: "CFR" },
  { value: "CIF", label: "CIF" },
  { value: "CIP", label: "CIP" },
  { value: "CPT", label: "CPT" },
  { value: "DAF", label: "DAF" },
  { value: "DES", label: "DES" },
  { value: "DDP", label: "DDP" },
  { value: "DAT", label: "DAT" },
  { value: "DAP", label: "DAP" },
];

export const SpecificationToolTipText =
  'Many products need further specification, as they are available in many different types. Use the field "Specifications", to enter the exact details about the product you are offering or searching. You can also enter any further details here, which you think are useful for our agents to identify your best business partner. Maybe you can provide the quantity of the product you search or name some quality requirements. For example: You are searching for Glycerine. Glycerine has hundreds of variations and usages so please specify e.g.: "Glycerine USP grade, 100% veggie based, shipping in Flexitanks, qty. approx: 100MT per month';
export const TargetToolTipText =
  'What industry or customer group is typically using the product you are offering? Enter these information in the field "target" industry or customer group. For example: You are offering chemical raw material, which has various uses and is used by different industries. Name these industries in the designated field e.g.: "Food production, leather treatment, dye factory.';

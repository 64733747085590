import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ButtonSpinner, userLevelColors } from "../services/globalFunctions";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { base_url, countryList, incotermOptions } from "../services/const";
import { Accordion } from "react-bootstrap";
import MyProductsListItem from "../components/MyProductsListItem";

let timeInterval;
const typeFilter = [
  { label: "Search", value: "search" },
  { label: "Offer", value: "offer" },
];

const userLevelFilter = [
  { label: "Jumpseat", value: "dk-jumpseat,dk-temp" },
  { label: "Economy", value: "dk-economy" },
  { label: "Business", value: "dk-business" },
  { label: "First Class", value: "dk-first_class" },
  { label: "Trading Company", value: "trading_company" },
];

class CommoditiesSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: "",
      accordionItems: [],
      countrySearch: "",
      selectedHsCode: "",
      categories: [],
      countries: countryList,
      selectedCountries: [],
      minPrice: "",
      maxPrice: "",
      minQuantity: "",
      incoterm: [],
      searchResult: [],
      type: "",
      loading: false,
      userLevel: "",
    };
    this.fetchCategories = this.fetchCategories.bind(this);
    this.onCountrySearch = this.onCountrySearch.bind(this);
    this.onChangeIncoterm = this.onChangeIncoterm.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.onChangeCategoriesCheckbox =
      this.onChangeCategoriesCheckbox.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeUserLevel = this.onChangeUserLevel.bind(this);
  }

  componentDidMount() {
    this.fetchCategories();
    this.searchItem();
  }

  fetchCategories() {
    axios
      .request({
        method: "GET",
        url: `${base_url}/commodities/list`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.data.data) {
          const data = ret.data.data;
          let obj = [];
          obj.push({ title: "All Categories", children: [] });
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (item.level === 1) {
              item.children = [];
              for (let k = 0; k < data.length; k++) {
                const child = data[k];
                if (child.parent === item.hscode) {
                  item.children.push(child);
                }
              }
              obj.push(item);
            }
          }
          this.setState({ categories: obj });
        }
      });
  }

  onCountrySearch(e) {
    const value = e.target.value;
    const countries = countryList.filter((e) =>
      e.toLowerCase().includes(value.toLowerCase())
    );
    if (countries.length > 0) {
      this.setState({ countries }, this.searchItem);
    } else {
      this.setState({ countries: countryList }, this.searchItem);
    }
  }

  onChangeCountryCheckbox(e) {
    const value = e.target.value;
    const { selectedCountries } = this.state;
    if (selectedCountries.includes(value)) {
      const filtered = selectedCountries.filter((e) => e !== value);
      this.setState({ selectedCountries: filtered }, this.searchItem);
    } else {
      this.setState(
        { selectedCountries: [...selectedCountries, value] },
        this.searchItem
      );
    }
  }

  onChangeIncoterm(e) {
    const value = e.target.value;
    const { incoterm } = this.state;
    if (incoterm.includes(value)) {
      const filtered = incoterm.filter((e) => e !== value);
      this.setState({ incoterm: filtered }, this.searchItem);
    } else {
      this.setState({ incoterm: [...incoterm, value] }, this.searchItem);
    }
  }

  onChangeUserLevel(e) {
    const value = e.target.value;
    const { userLevel } = this.state;
    if (userLevel.includes(value)) {
      const filtered = userLevel.filter((e) => e !== value);
      this.setState({ userLevel: filtered }, this.searchItem);
    } else {
      this.setState({ userLevel: [...userLevel, value] }, this.searchItem);
    }
  }

  onChangeType(e) {
    const value = e.target.value;
    const { type } = this.state;
    if (type === value) {
      this.setState({ type: "" }, this.searchItem);
    } else {
      this.setState({ type: value }, this.searchItem);
    }
  }

  searchItem() {
    const {
      type,
      searchVal,
      maxPrice,
      minPrice,
      selectedHsCode,
      selectedCountries,
      incoterm,
      minQuantity,
      userLevel,
    } = this.state;
    this.setState({ loading: true });
    let url = `${base_url}/commodities/search/100/0?`;
    if (type) {
      url += `type=${type}&`;
    }
    if (searchVal) {
      url += `search=${searchVal}&`;
    }
    if (maxPrice) {
      url += `min_price=${maxPrice}&`;
    }
    if (minPrice) {
      url += `max_price=${minPrice}&`;
    }
    if (selectedHsCode) {
      url += `hscode=${selectedHsCode}&`;
    }
    if (selectedCountries.length > 0) {
      url += `country=${selectedCountries.join(",")}&`;
    }
    if (incoterm.length > 0) {
      url += `incoterm=${incoterm.join(",")}&`;
    }
    if (userLevel.length > 0) {
      console.log(userLevel.join());
      url += `user_level=${userLevel.join(",")}&`;
    }
    if (minQuantity) {
      url += `quantity=${minQuantity}`;
    }
    clearTimeout(timeInterval);
    timeInterval = setTimeout(() => {
      axios
        .request({
          method: "GET",
          url: url,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${this.props.token}`,
          },
        })
        .then((ret) => {
          if (ret.data.data && ret.data.status === 200) {
            this.setState({ searchResult: ret.data.data, loading: false });
          } else {
            this.setState({ searchResult: [], loading: false });
          }
        });
    }, 1000);
  }

  onChangeCategoriesCheckbox(e) {
    const { selectedHsCode } = this.state;
    if (selectedHsCode === e) {
      this.setState({ selectedHsCode: "" }, this.searchItem);
    } else {
      this.setState({ selectedHsCode: e }, this.searchItem);
    }
  }

  onChangeSearch(e) {
    this.setState({ searchVal: e.target.value }, this.searchItem);
  }

  render() {
    const {
      loading,
      type,
      searchVal,
      searchResult,
      maxPrice,
      minPrice,
      categories,
      countries,
      selectedCountries,
      incoterm,
      minQuantity,
      selectedHsCode,
      userLevel,
    } = this.state;
    const { level } = this.props;
    return (
      <div className="page-container AdminPageContainer CommoditiesSearch">
        <div className="page-header">
          <form
            className="form-group col-5 position-absolute topsearch-container row"
            style={{ bottom: "55px" }}
          >
            <h1 className="search-heading">Search Commodities</h1>
            <input
              className="form-control topsearch"
              placeholder="Search.."
              value={searchVal}
              onChange={this.onChangeSearch}
            />
            <button
              type="submit"
              className="navbar-search-btn bg-transparent border-0"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
        </div>
        <div
          className="w-75 mt-4"
          style={{ marginLeft: "50%", transform: "translate(-50%)" }}
        >
          <div className="row">
            <div
              className="col-lg-4 box mb-4"
              style={{ height: "fit-content" }}
            >
              <h2 style={{ fontSize: "22px" }} className="pt-2">
                Filters
              </h2>
              <div className="categories-filter">
                <p
                  className="font-weight-bold p-2 my-2"
                  style={{
                    color: "#04246a",
                    backgroundColor: "rgba(0,0,0, 0.03)",
                  }}
                >
                  Type
                </p>
                <ul className="pl-1 mt-2 search-offer-filter">
                  {typeFilter.map((item, i) => {
                    return (
                      <li className="list-unstyled py-1" key={i}>
                        <div className="form-check">
                          <input
                            checked={type === item.value}
                            onChange={this.onChangeType}
                            type="checkbox"
                            value={item.value}
                            className="form-check-input"
                            id={item.value}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={item.value}
                          >
                            {item.label}
                          </label>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="categories-filter">
                <p
                  className="font-weight-bold p-2 my-2"
                  style={{
                    color: "#04246a",
                    backgroundColor: "rgba(0,0,0, 0.03)",
                  }}
                >
                  Membership
                </p>
                <ul className="pl-1 mt-2 search-offer-filter">
                  {userLevelFilter.map((item, i) => {
                    return (
                      <li className="list-unstyled py-1" key={i}>
                        <div className="form-check">
                          <input
                            checked={userLevel.includes(item.value)}
                            onChange={this.onChangeUserLevel}
                            type="checkbox"
                            value={item.value}
                            className="form-check-input"
                            id={item.value}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={item.value}
                          >
                            {item.label}
                          </label>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <p
                className="font-weight-bold p-2 my-2"
                style={{
                  color: "#04246a",
                  backgroundColor: "rgba(0,0,0, 0.03)",
                  //color: userLevelColors(level).buttonFont,
                  //backgroundColor: userLevelColors(level).background
                }}
              >
                Categories
              </p>

              <div className="categories-filter categoriesFilterFixed">
                {/* <p
                  className="font-weight-bold p-2 my-2"
                  style={{
                    color: userLevelColors(level).buttonFont,
                    backgroundColor: userLevelColors(level).background,
                  }}
                >
                  Categories
                </p> */}
                <Accordion defaultValue={0} className="accordion">
                  {categories.map((item, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          backgroundColor:
                            item.title !== "All Categories"
                              ? "#edf0f4"
                              : "#fff",
                          borderBottom: "1px solid #032469",
                          //   background: userLevelColors(level).background,
                          //   border: `1px solid ${
                          //     userLevelColors(level).buttonFont
                          //   }`,
                        }}
                      >
                        {item.title !== "All Categories" ? (
                          <Accordion.Toggle
                            eventKey={i.toString()}
                            className="w-100 p-2"
                            onClick={() =>
                              this.onChangeCategoriesCheckbox(item.hscode)
                            }
                            style={{
                              color: "#032469",
                              background: "#edf0f4",
                              border: "none",
                              //border: "none",
                              //background: userLevelColors(level).background,
                              //color: userLevelColors(level).buttonFont,
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <p
                                className="m-0 text-left"
                                style={{
                                  width: "98%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.title}
                              </p>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </div>
                          </Accordion.Toggle>
                        ) : (
                          <Button
                            className="text-capitalize"
                            onClick={() =>
                              this.onChangeCategoriesCheckbox(item.hscode)
                            }
                            style={{
                              border: "none",
                              backgroundColor: "#fff ",
                              color: "#032469",
                              //background: userLevelColors(level).background,
                              //color: userLevelColors(level).buttonFont,
                              width: "100%",
                              textAlign: "left",
                              fontWeight: "normal",
                              paddingLeft: 9,
                              fontSize: 16,
                            }}
                          >
                            All Categories
                          </Button>
                        )}
                        <Accordion.Collapse eventKey={i.toString()}>
                          <ul className="p-0 mb-0">
                            {item.children.map((hsCode, i) => {
                              return (
                                <li
                                  className="list-unstyled px-3 py-1 innerListCategories"
                                  key={hsCode.hscode_id}
                                >
                                  <div className=" d-flex align-items-center">
                                    <label
                                      className="mb-0"
                                      style={{
                                        cursor: "pointer",
                                        color: "#032469",
                                        //color:userLevelColors(level).buttonFont,
                                      }}
                                      onClick={() =>
                                        this.onChangeCategoriesCheckbox(
                                          hsCode.hscode
                                        )
                                      }
                                    >
                                      {i + 1}. {hsCode.title}
                                    </label>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </Accordion.Collapse>
                      </div>
                    );
                  })}
                </Accordion>
              </div>
              <div className="categories-filter">
                <p
                  className="font-weight-bold p-2 my-2"
                  style={{
                    color: "#04246a",
                    backgroundColor: "rgba(0,0,0, 0.03)",
                    // color: userLevelColors(level).buttonFont,
                    // backgroundColor: userLevelColors(level).background,
                  }}
                >
                  Origin
                </p>
                <div className="position-relative">
                  <input
                    className="form-control topsearch"
                    placeholder="Search.."
                    onChange={this.onCountrySearch}
                  />
                  <button
                    type="submit"
                    style={{ position: "absolute", right: "20px", top: "4px" }}
                    className="navbar-search-btn bg-transparent border-0"
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
                <div>
                  <ul
                    className="pl-1 mt-2"
                    style={{ height: 200, overflow: "scroll" }}
                  >
                    {countries.map((item, i) => {
                      return (
                        <li className="list-unstyled py-1" key={i}>
                          <div className="form-check">
                            <input
                              checked={selectedCountries.includes(item)}
                              onChange={this.onChangeCountryCheckbox}
                              type="checkbox"
                              value={item}
                              className="form-check-input"
                              id={item}
                            />
                            <label className="form-check-label" htmlFor={item}>
                              {item}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* <div className="categories-filter">
                  <p
                    className="font-weight-bold p-2 my-2"
                    style={{
                      color: userLevelColors(level).buttonFont,
                      backgroundColor: userLevelColors(level).background,
                    }}
                  >
                    Price range
                  </p>
                  <div className="d-flex">
                    <input
                      type="number"
                      placeholder="Min"
                      value={maxPrice}
                      onChange={(e) =>
                        this.setState(
                          { maxPrice: e.target.value },
                          this.searchItem
                        )
                      }
                      className="col-lg-5 form-control topsearch"
                    />
                    <p className="col-lg-2 text-center">__</p>
                    <input
                      type="number"
                      value={minPrice}
                      onChange={(e) =>
                        this.setState(
                          { minPrice: e.target.value },
                          this.searchItem
                        )
                      }
                      placeholder="Max"
                      className="col-lg-5 form-control topsearch"
                    />
                  </div>
                </div> */}
                {/* <div className="categories-filter">
                  <p
                    className="font-weight-bold p-2 my-2"
                    style={{
                      color: userLevelColors(level).buttonFont,
                      backgroundColor: userLevelColors(level).background,
                    }}
                  >
                    Min quantity order
                  </p>
                  <div className="d-flex">
                    <input
                      value={minQuantity}
                      onChange={(e) =>
                        this.setState(
                          { minQuantity: e.target.value },
                          this.searchItem
                        )
                      }
                      type="number"
                      placeholder="Less than"
                      className="col-lg-12 form-control topsearch"
                    />
                  </div>
                </div> */}
                <div className="categories-filter">
                  <p
                    className="font-weight-bold p-2 my-2"
                    style={{
                      color: "#04246a",
                      backgroundColor: "rgba(0,0,0, 0.03)",
                      // color: userLevelColors(level).buttonFont,
                      // backgroundColor: userLevelColors(level).background,
                    }}
                  >
                    Incoterm
                  </p>
                  <ul
                    className="pl-1 mt-2"
                    style={{ height: 200, overflow: "scroll" }}
                  >
                    {incotermOptions.map((item, i) => {
                      return (
                        <li className="list-unstyled py-1" key={i}>
                          <div className="form-check">
                            <input
                              checked={incoterm.includes(item.value)}
                              onChange={this.onChangeIncoterm}
                              type="checkbox"
                              value={item.value}
                              className="form-check-input"
                              id={item.value}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={item.value}
                            >
                              {item.value}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              {searchResult.length > 0 && !loading ? (
                searchResult.map((item) => {
                  return (
                    <MyProductsListItem
                      key={item.pns_id}
                      item={item}
                      hideButton={true}
                    />
                  );
                })
              ) : loading ? (
                <div
                  style={{
                    marginTop: 50,
                    marginLeft: "48%",
                    height: "3.3em",
                    width: "3.3em",
                    color: userLevelColors(level).bg,
                  }}
                  className={"spinner-border "}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div
                  className={
                    "searchresult w-100 row p-3 mb-4 position-relative col-lg-12 "
                  }
                >
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="col-lg-2">
                      <img
                        style={{
                          height: "70px",
                          width: "auto",
                          objectFit: "contain",
                          maxWidth: "200px",
                        }}
                        src={require("../assets/images/WorldRing.png")}
                        alt="..."
                      />
                    </div>
                    <div className="col-lg-6 ml-3">
                      No commodities found. Use the filter to find products.
                    </div>
                    <div className="col-lg-2 ml-3"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CommoditiesSearch);
